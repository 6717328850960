<ng-container *ngIf="representative$ | async as representative">
  <h5 class="account-title mt-0 mb-3">{{ 'representative.title' | translate }}</h5>
  <div class="representative-box card border">
    <h5 class="representative-name">{{ representative.details?.Name }}</h5>
    <div class="representative-job">{{ representative.details?.JobTitle }}</div>
    <div class="representative-content">
      <div class="img-wrapper" *ngIf="repImage">
        <img class="rounded" alt="" [src]="repImage" />
      </div>
      <div class="representative-details">
        <div class="representative-info representative-mail">
          <span class="material-icons">mail</span>
          <span class="text">{{ representative.details?.EmailAddress }}</span>
        </div>
        <div class="representative-info representative-phone">
          <span class="material-icons">phone</span>
          <span class="text">{{ representative.details?.PhoneNumber }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
