<div class="mobile-nav-menu d-flex position-fixed flex-column d-lg-none">
  <!-- NAV BAR -->
  <div class="mobile-nav-bar d-flex align-items-center justify-content-between row">
    <ng-container *notInProductCode="productcode.NonRenewing">
      <div class="d-flex col-4">
        <fgb-notification-button></fgb-notification-button>
      </div>
    </ng-container>
    <div *inProductCode="productcode.NonRenewing" class="col-4"></div>
    <a class="nav-item fw-bold brand p-1 col-4" [routerLink]="['/']" routerLinkActive="router-link-active">
      <label id="homeLink" class="accessibility-only">Go To Home Page</label>
      <img class="home-logo" src="assets/images/logo.png" draggable="false" />
    </a>

    <button
      attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
      class="nav-item d-block fw-bold navbar-toggler m-0 px-2 col-4 text-end"
      (click)="isCollapsed = !isCollapsed"
    >
      <span class="icon material-icons font-size-22">menu</span>
    </button>
  </div>

  <!-- NAV SCREEN -->
  <div [ngbCollapse]="isCollapsed" class="mobile-nav-menu-dropdown">
    <div class="d-flex flex-column pt-1 mobile-nav-menu-content">
      <!-- Close Button -->
      <button
        class="close-button border-0 d-flex align-items-center justify-content-center pt-3"
        attr.aria-label="{{ 'modal.close' | translate }}"
        (click)="isCollapsed = !isCollapsed"
      >
        <i class="material-icons close-btn text-primary">cancel</i>
      </button>
      <ng-container *notInProductCode="productcode.NonRenewing">
        <div class="container">
          <ng-container *inProductCode="productcode.STH">
            <fgb-member-card [isMobileMenu]="true"></fgb-member-card>
          </ng-container>
          <ng-container *inProductCode="productcode.SingleGameBuyer">
            <fgb-member-card [isMobileMenu]="true"></fgb-member-card>
          </ng-container>
          <ng-container *notInProductCode="productcode.SingleGameBuyer">
            <div class="row mx-0">
              <div class="col-6 ps-0">
                <a
                  class="nav-link-button"
                  [routerLink]="['/rewards/wallet']"
                  [routerLinkActive]="'active'"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <span class="material-icons icon"> qr_code </span>
                  {{ 'menu.nav.wallet' | translate }}
                </a>
              </div>
              <div class="col-6 ps-0">
                <a
                  class="nav-link-button"
                  [routerLink]="['/rewards/events']"
                  [routerLinkActive]="'active'"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <span class="material-icons icon"> local_activity </span>
                  {{ 'menu.nav.events' | translate }}
                </a>
              </div>
            </div>

            <div class="row mx-0 mt-15px pb-4 border-bottom">
              <div class="col-6 ps-0">
                <a
                  class="nav-link-button"
                  [routerLink]="['/rewards/marketplace']"
                  [routerLinkActive]="'active'"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <span class="material-icons icon"> redeem </span>
                  {{ 'menu.nav.giveaways' | translate }}
                </a>
              </div>
              <div class="col-6 ps-0">
                <a class="nav-link-button" href="{{ 'more.info.url' | translate }}">
                  <span class="material-icons icon"> info </span>
                  {{ 'menu.nav.benefits' | translate }}
                </a>
              </div>
            </div>
          </ng-container>
          <ng-container *inProductCode="productcode.SingleGameBuyer">
            <div class="row mx-0">
              <div class="col-12 px-0">
                <a
                  class="nav-link-button"
                  [routerLink]="['/rewards/wallet']"
                  [routerLinkActive]="'active'"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <span class="material-icons icon"> account_balance_wallet </span>
                  {{ 'menu.nav.wallet' | translate }}
                </a>
              </div>
            </div>
            <div class="row mx-0 mt-15px pb-4 border-bottom">
              <div class="col-6 ps-0">
                <a
                  class="nav-link-button"
                  [routerLink]="['/rewards']"
                  [routerLinkActive]="'active'"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <span class="material-icons icon"> redeem </span>
                  {{ 'mobile.nav.rewards' | translate }}
                </a>
              </div>
              <div class="col-6 px-0">
                <a
                  class="nav-link-button"
                  [routerLink]="['/badges']"
                  [routerLinkActive]="'active'"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <span class="material-icons icon"> emoji_events </span>
                  {{ 'menu.nav.challenges' | translate }}
                </a>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <!-- Nav Buttons -->
      <div class="mobile-nav-button-container">
        <ng-container *notInProductCode="productcode.NonRenewing">
          <a
            class="nav-item text-primary"
            [routerLink]="['/contact']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">email</span>
            <div class="nav-text fw-bold font-praktika-bold">{{ 'nav.contact.us' | translate }}</div>
            <span class="icon material-icons font-size-24 ms-auto">keyboard_arrow_right</span>
          </a>

          <a
            class="nav-item text-primary"
            [routerLink]="['/terms']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">article</span>
            <div class="nav-text fw-bold font-praktika-bold">{{ 'nav.terms' | translate }}</div>
            <span class="icon material-icons font-size-24 ms-auto">keyboard_arrow_right</span>
          </a>

          <a
            class="nav-item text-primary"
            [routerLink]="['/faq']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">help</span>
            <div class="nav-text fw-bold font-praktika-bold">{{ 'nav.faq' | translate }}</div>
            <span class="icon material-icons font-size-24 ms-auto">keyboard_arrow_right</span>
          </a>
          <div class="w-100 border-bottom"></div>
        </ng-container>
        <ng-container *inProductCode="productcode.NonRenewing">
          <div class="w-100 mt-4 border-bottom"></div>
        </ng-container>
        <a class="nav-item text-primary" (click)="logout()">
          <span class="icon material-icons material-mobile-dropdown me-3" aria-hidden="true">power_settings_new</span>
          <div class="nav-text fw-bold font-praktika-bold">{{ 'nav.log.out' | translate }}</div>
        </a>
      </div>
    </div>
  </div>
</div>
