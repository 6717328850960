<div class="container mt-1">
  <div class="row">
    <div class="col-12 mb-lg-3 mb-0">
      <fgb-member-card [isAccountPage]="true"></fgb-member-card>
    </div>
    <div class="col-lg-6 col-12">
      <h5 class="account-title mt-0 mb-3">{{ 'account.details.title' | translate }}</h5>
      <fgb-account-details></fgb-account-details>
    </div>
    <div class="col-lg-6 col-12 mt-lg-0 mt-4" *notInProductCode="productcode.SingleGameBuyer">
      <fgb-my-representative></fgb-my-representative>
    </div>
  </div>
</div>
